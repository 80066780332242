.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
}

.hamtop,
.hammiddle,
.hambottom {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 2px;
  background: #e75124;
  transform: rotate(0);
  transition: all 0.5s;
}

.hammiddle {
  transform: translateY(7px);
}

.hambottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90 deg);
  transform: translateY(0px);
}

.open .hamtop {
  transform: rotate(45deg) translateY(6px) translate(6px);
}

.open .hammiddle {
  display: none;
}

.open .hambottom {
  transform: rotate(-45deg) translateY(6px) translate(-6px);
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}

.menuwithdd:hover .dd_flyer {
  transform: scale(-1.05);
}

.nav-dropdown:hover .nav-dropdown-menu {
  display: block;
}

.nav-dropdown-menu {
  pointer-events: none;
}
/* 
.dropdown {
  cursor: pointer;
  position: relative;
  text-decoration: none;
  z-index: 1;
  transform: perspective(1000px);
   -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
} */

.megaMenuANI:hover .megaMenuChildANI {
  /* background: rgb(193, 23, 23); */
  transform: translate(0rem, 1rem);
  opacity: 1;
  transition-delay: 1s;
  visibility: visible;
  /* top: 100%; */
  /* width: 100%; */
  -webkit-transition: all 0.2s, background, 2s 2s linear;
  transition: all 0.5s, background 2s 0.5s linear;
  /* 
 
   transform: translateY( 30px) ; */
}
