@import url("https://fonts.googleapis.com/css?family=Hind:300,400&display=swap");

* {
  box-sizing: border-box;
}
*::before,
*::after {
  box-sizing: border-box;
}

/* body {
  margin: 0;
  padding: 0;
  font-family: "Hind", sans-serif;
  background: #fff;
  color: #4d5974;
  display: flex;
  min-height: 100vh;
} */

.container {
  margin: 0 auto;
  padding: 4rem;
  width: 48rem;
}

.accordion .accordion-item {
  border-bottom: 1px solid #e5e5e5;
}

.accordion .accordion-item button[aria-expanded="true"] {
  border-bottom: 1px solid #e5e5e5;
}

.accordion button {
  position: relative;
  display: block;
  text-align: start;
  width: 100%;
  padding: 1em 0;
  color: #1f2937;
  font-size: 1.15rem;
  font-weight: 400;
  border: none;
  background: none;
  outline: none;
}

.accordion button:hover,
.accordion button:focus {
  cursor: pointer;
  color: #1f2937;
}

.accordion button:hover::after,
.accordion button:focus::after {
  cursor: pointer;
  color: #1f2937;
  border: 1px solid #1f2937;
}

.accordion button .accordion-title {
  padding: 1em 1.5em 1em 0;
}

.accordion button .icon {
  display: inline-block;
  position: absolute;
  top: 30%;
  right: 0;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 22px;
}

.accordion button .icon::before {
  display: block;
  position: absolute;
  content: "";
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: currentColor;
}
.accordion button .icon::after {
  display: block;
  position: absolute;
  content: "";
  top: 5px;
  left: 9px;
  width: 2px;
  height: 10px;
  background: currentColor;
}

.accordion button[aria-expanded="true"] {
  color: #1f2937;
}
.accordion button[aria-expanded="true"] .icon::after {
  width: 0;
}
.accordion button[aria-expanded="true"] + .accordion-content {
  opacity: 1;
  max-height: 150px;
  /* transition: all 600ms ease-in-out; */
  transition: all 0.3s ease-in-out;
  transition-duration: 300ms;
  will-change: opacity, max-height;
  overflow-y: scroll;
  /* overflow: -moz-scrollbars-none; */
}

.accordion
  button[aria-expanded="true"]
  + .accordion-content::-webkit-scrollbar {
  width: 0 !important;
}

.accordion button[aria-expanded="true"] + .accordion-content {
  overflow: -moz-scrollbars-none;
}

.accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  /* transition: opacity 100ms ease-in-out, max-height 100ms ease-in-out; */
  transition: all 0.3s ease-in-out;
  will-change: opacity, max-height;
}

.accordion .accordion-content p {
  font-size: 1rem;
  font-weight: 300;
  margin: 2em 0;
}

/* Styling the iframe to fit in the container div with full height and width [Used in Contact Us Page]*/

.iframe-container-large {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 30%;
  /* padding-top: 56.25%; */
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.iframe-container-small {
  position: relative;
  overflow: hidden;
  width: 100%;
  /* padding-top: 30%; */
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.bgmodified {
  -webkit-tap-highlight-color: transparent;
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noSelect:focus {
  outline: none !important;
}
