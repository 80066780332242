/* .activeButton::after {
  position: absolute;
  bottom: -4px;
  left: 0;
  content: "";
  width: 100%;
  height: 2px;
  background: rgb(240, 10, 10);
  transform: scale(0, 1);
  transform-origin: right top;
  transition: transform 0.3s;
}

.activeButton:hover::after {
  transform-origin: left top;
  transform: scale(1, 1);
}

.activeButton:hover {
  transform-origin: left top;
  transform: scale(1, 1);
} */

mark {
  display: inline-block;
  background: #e75124 !important;
  line-height: 0em;
  padding-bottom: 0.5em;
}

.htext {
  letter-spacing: 2px;
  padding: 0 5px;
  background: linear-gradient(to bottom, transparent 70%, #f7663a 50%);
}
