


 .megaMenuAnimate:hover .megaMenuChildAnimate {
    background: rgb(255, 255, 255);
    opacity: 1;
    transition-delay: 2s;
    visibility: visible;
    top: 100%;
    width: 100%;
    -webkit-transition: all .2s, background, 2s 2s linear;
    transition: all .5s, background 2s .5s linear;
     transform: translateY( 30px)  scaleY(1.09);
  }

