

  .megaMenu:hover .megaMenuChild {
    /* background: rgb(193, 23, 23); */
    transform: translate(0rem , 1rem);
    opacity: 1;
    transition-delay: 1s;
    visibility: visible;
    /* top: 100%; */
    /* width: 100%; */
    -webkit-transition: all .2s, background, 2s 2s linear;
    transition: all .5s, background 2s .5s linear;
/* 
   
     transform: translateY( 30px) ; */
  }

  


  




